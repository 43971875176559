<template>
  <div>
    <h3 class="font-weight-bold mb-0 text-primary">
      จัดการบัญชีฝากออโต้
    </h3>
    <button
      class="btn btn-gradient-primary my-1"
      @click="$refs['add-account'].show()"
    >
      <i class="fas fa-plus-circle" /> เพิ่มบัญชี
    </button>
    <hr>

    <div class="ctx-status">

      <h4 class="text-primary">
        สถานะบัญชี <small>(ทั้งหมด 4 บัญชี)</small>
      </h4>
      <div class="row">
        <div
          v-for="(item, index) in accList"
          :key="index"
          class="col-md-3"
        >
          <div
            class="first hero"
            :class="item.status === 'active' ? 'active' : 'deactive'"
          >

            <button
              class="btn-clear"
              @click="showModal(index)"
            >
              <i class="fas fa-trash" />
            </button>

            <div class="-bg-acc" />
            <div class="hero-description-bk" />
            <div class="hero-logo">
              <img
                src="/logo/logo-sv.png"
                alt=""
              >
            </div>
            <div class="hero-description">
              <p class="acc mb-0">
                {{ item.accountNumber }}
              </p>
              <p class="-id mb-0">
                {{ item.idWebsite }}
              </p>
              <p class="-text-name mb-0">
                {{ item.fullname }}
              </p>
            </div>
            <div class="hero-date">
              <p>สถานะการใช้งาน</p>
            </div>
            <div class="hero-btn">
              {{ item.status === 'active' ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal add account here -->
    <b-modal
      ref="add-account"
      title="เพิ่มบัญชีฝากออโต้"
      ok-title="บันทึกข้อมูล"
      cancel-title="ยกเลิก"
      @ok.prevent="handleOk"
    >
      <validation-observer ref="observer">
        <div class="my-1">
          <validation-provider
            #default="{ errors }"
            name="Account Number"
            rules="required"
          >
            <p class="acc_np mb-0">
              เลขที่บัญชี
            </p>
            <b-form-input
              v-model="accountNumber"
              :state="errors.length > 0 ? false:null"
              placeholder="กรอกเลขที่บัญชี"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="my-1">
          <validation-provider
            #default="{ errors }"
            name="ID Website"
            rules="required"
          >
            <p class="acc_np mb-0">
              ID Website
            </p>
            <b-form-input
              v-model="idWebsite"
              :state="errors.length > 0 ? false:null"
              placeholder="กรอก ID Website"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="my-1">
          <validation-provider
            #default="{ errors }"
            name="Password"
            rules="required"
          >
            <p class="acc_np mb-0">
              รหัสผ่านหน้าเว็บ
            </p>
            <b-form-input
              v-model="password"
              type="password"
              :state="errors.length > 0 ? false:null"
              placeholder="กรอกรหัสผ่านหน้าเว็บ"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>

        <div class="my-1">
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <p class="acc_np mb-0">
              ชื่อ-นามสกุล
            </p>
            <b-form-input
              v-model="fullname"
              :state="errors.length > 0 ? false:null"
              placeholder="กรอกชื่อ-นามสกุล"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </validation-observer>
    </b-modal>

    <!-- modal confirm delete -->
    <b-modal
      ref="modal-del"
      title="ยืนยันการลบบัญชี"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="deleteItem(items)"
    >
      <p class="my-1">
        คุณต้องการลบบัญชีนี้ใช่หรือไม่ {{ items }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { BFormInput, BModal } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  data() {
    return {
      required,
      name: '',
      selected: '1',
      accountNumber: '',
      idWebsite: '',
      password: '',
      fullname: '',
      items: [],

      accList: [
        {
          accountNumber: '012-123-1223',
          idWebsite: 'IDTEST-1',
          fullname: 'สมทวย คงควยคอย',
          status: 'active',
        },
        {
          accountNumber: '012-123-1223',
          idWebsite: 'IDTESR-2',
          fullname: 'ลมโชย หอมหวน',
          status: 'deactive',
        },
        {
          accountNumber: '012-123-1223',
          idWebsite: 'IDTEST-3',
          fullname: 'สุรต่าน เงินเหลือ',
          status: 'deactive',
        },
        {
          accountNumber: '012-123-1223',
          idWebsite: 'IDTEST-4',
          fullname: 'บานเย็น หนาวใจ',
          status: 'deactive',
        },
      ],
    }
  },
  methods: {
    handleOk() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-account')
        }
      })
    },
    showModal(val) {
      this.$refs['modal-del'].show()
      this.items = val
    },
    deleteItem(val) {
      this.$refs['modal-del'].hide()
      console.log(val)
    },
  },

}
</script>

<style lang="scss" scoped>
input[type="radio"] {
  display: none;
  &:not(:disabled) ~ label {
    cursor: pointer;
  }
  &:disabled ~ label {
    color: hsla(150, 5%, 75%, 1);
    border-color: hsla(150, 5%, 75%, 1);
    box-shadow: none;
    cursor: not-allowed;
  }
}
label {
  height: 100%;
  display: block;
  background: white;
  border: 2px solid hsla(150, 75%, 50%, 1);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;
  //margin: 1rem;
  text-align: center;
  box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);
  position: relative;
}
input[type="radio"]:checked + label {
  background: hsla(150, 75%, 50%, 1);
  color: hsla(215, 0%, 100%, 1);
  box-shadow: 0px 0px 20px hsla(150, 100%, 50%, 0.75);
  &::after {
    color: hsla(215, 5%, 25%, 1);
    font-family: "Font Awesome 5 Free";
    border: 2px solid hsla(150, 75%, 45%, 1);
    content: "🔔";
    font-size: 24px;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50%;
    background: white;
    box-shadow: 0px 2px 5px -2px hsla(0, 0%, 0%, 0.25);
  }
}
input[type="radio"]#control_05:checked + label {
  background: red;
  border-color: red;
}
p {
  font-weight: 900;
}

@media only screen and (max-width: 700px) {
  section {
    flex-direction: column;
  }
}
</style>
